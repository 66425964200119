<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">上报报表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  <!--  铁路工程管理平台进度、安全步距数据-->
    <div style="margin: auto;width: 90%">
      <div style="display: flex;justify-content: space-between;margin-bottom: 1%">
        <div style="width: 20%">
          工点名称
        </div>
        <div style="width: 20%">
          铁管平台开累进度
        </div>
        <div style="width: 20%">
          铁管平台二衬安全步距
        </div>
        <div style="width: 20%">
          铁管平台仰拱安全步距
        </div>
      </div>
      <el-divider />
      <div v-for="(item, index) in portals">
        <div style="display: flex;justify-content: space-between">
          <div style="width: 20%">
            {{item.opername}}
          </div>
          <div style="width: 20%">
            {{item.milesum}}
          </div>
          <div style="width: 20%;background: red" v-if="item.ecbjAlert - item.ecbj < 0">
            {{item.ecbj}} / {{item.ecbjAlert}}
          </div>
          <div style="width: 20%;background: orange" v-if="item.ecbjAlert - item.ecbj < 10 && item.ecbjAlert - item.ecbj >= 0">
            {{item.ecbj}} / {{item.ecbjAlert}}
          </div>
          <div style="width: 20%;background: yellow" v-if="item.ecbjAlert - item.ecbj < 15 && item.ecbjAlert - item.ecbj >= 10">
            {{item.ecbj}} / {{item.ecbjAlert}}
          </div>
          <div style="width: 20%;" v-if="item.ecbjAlert - item.ecbj >= 15">
            {{item.ecbj}} / {{item.ecbjAlert}}
          </div>
          <div style="width: 20%;background: red" v-if="item.ygbjAlert - item.ygbj < 0">
            {{item.ygbj}} / {{item.ygbjAlert}}
          </div>
          <div style="width: 20%;background: orange" v-if="item.ygbjAlert - item.ygbj < 10 && item.ygbjAlert - item.ygbj >= 0">
            {{item.ygbj}} / {{item.ygbjAlert}}
          </div>
          <div style="width: 20%;background: yellow" v-if="item.ygbjAlert - item.ygbj < 15 && item.ygbjAlert - item.ygbj >= 10">
            {{item.ygbj}} / {{item.ygbjAlert}}
          </div>
          <div style="width: 20%;" v-if="item.ygbjAlert - item.ygbj >= 15">
            {{item.ygbj}} / {{item.ygbjAlert}}
          </div>
        </div>
        <el-divider />
      </div>

    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
export default {
  name: "procoll_comparison",
  components: {Header},
  data(){
    return{
      portals: []
    }
  },
  created() {
    this.getPortal();
  },
  methods:{
    getPortal(){
      this.axios.get('/synRemp/getBjDetail', (response) => {
       this.portals = response.obj;
      })
    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
</style>